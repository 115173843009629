import React from 'react';
import logo from './logo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        {/*<p>*/}
        {/*  Find delicious ramen dishes by searching the recipe ingredients.*/}
        {/*</p>*/}
        {/*<p>*/}
        {/*  Find unique ramen dishes by searching recipe ingredients.*/}
        {/*</p>*/}
        {/*<a*/}
        {/*  className="App-link"*/}
        {/*  href="http://ramendishes.com"*/}
        {/*  target="_blank"*/}
        {/*  rel="noopener noreferrer"*/}
        {/*>*/}
        {/*  Search Examples*/}
        {/*</a>*/}
      </header>
    </div>
  );
}

export default App;
